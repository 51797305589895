import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import kuhlmichelWebsite from "../../../../src/images/produkte/website/kuhmichel.jpg";
import medlWebsite from "../../../../src/images/produkte/website/medl.jpg";
import mwbWebsite from "../../../../src/images/produkte/website/mwb.jpg";
import oryxWebsite from "../../../../src/images/produkte/website/oryx.jpg";
import steckelmannWebsite from "../../../../src/images/produkte/website/seckelmann.jpg";
import mh025Website from "../../../../src/images/produkte/website/mh025-hero.jpg";
import derrWebsite from "../../../../src/images/produkte/website/wohnbau-derr.jpg";
import ssvKroschke from "../../../../src/images/produkte/website/ssv-kroschke.jpg";
import iww from "../../../../src/images/produkte/website/iww-website.jpg";
import contrade from "../../../../src/images/produkte/website/contrade-website.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  kuhlmichelWebsite,
  medlWebsite,
  mwbWebsite,
  oryxWebsite,
  steckelmannWebsite,
  mh025Website,
  derrWebsite,
  ssvKroschke,
  iww,
  contrade,
  placeholder,
  React
};